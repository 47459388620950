/* eslint-disable vue/multi-word-component-names */
import { createApp, reactive } from "vue";
import App from "./App.vue";
import PrimeVue from "primevue/config";
import router from "./router";
import store from "./store";

import "primevue/resources/themes/bootstrap4-light-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeflex/primeflex.min.css";
import "primeicons/primeicons.css";
import "@/assets/css/global.css";

import moment from "moment-timezone";
import MultiSelect from "primevue/multiselect";
import Toast from "primevue/toast";
import ToastService from "primevue/toastservice";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import Chip from "primevue/chip";
import Card from "primevue/card";
import Textarea from "primevue/textarea";
import Dropdown from "primevue/dropdown";
import DropDownRequire from "@/components/form-control/drop-down.vue";
import InputTextRequire from "@/components/form-control/input-text.vue";
import DropdownCustom from "@/components/form-control/dropdown-custom.vue";
// import InputDate from "@/components/form-control/input-date.vue";
import InputGroup from "@/components/form-control/group-input.vue";
import InputDate from "@/components/form-control/input-date.vue";
import InputCustomDate from "@/components/form-control/custom-date.vue";
import NumberCustom from "@/components/form-control/custom-number.vue";
import InputNumberRequire from "@/components/form-control/input-number.vue";
import InputGroupPercentage from "@/components/form-control/input-group-percentage.vue";
//  import LabelTooltip from "@/components/shared/label-tooltip.vue";
import Checkbox from "primevue/checkbox";
import Tooltip from "primevue/tooltip";
import Calendar from "primevue/calendar";
import FileUpload from "primevue/fileupload";
import ConfirmationService from "primevue/confirmationservice";
import ConfirmPopup from "@/components/modal-popup/confirm-popup.vue";
import InfoPopup from "@/components/modal-popup/info-popup.vue";
import ProgressSpinner from "primevue/progressspinner";
import clickOutside from "@/components/directive/useDetectOutsideClick";
import PreventRightClick from "@/components/directive/preventRightClick";
import ToggleButton from "primevue/togglebutton";
import Paginator from "primevue/paginator";
import IdleVue from "idle-vue";
import CascadeSelect from "primevue/cascadeselect";
import AutoComplete from "primevue/autocomplete";
import RadioButton from "primevue/radiobutton";
import Editor from "primevue/editor";
import EditorRequire from "@/components/form-control/editor.vue";

import CategoryAddPopup from "@/components/dialogPopup/category-popup.vue";
import CategorySelectionPopup from "@/components/dialogPopup/category-select.vue";
import VendorAddPopup from "@/components/dialogPopup/vendor-popup.vue";
import CustomerAddPopup from "@/components/dialogPopup/customer-popup.vue";
import MenuAddPopup from "@/components/dialogPopup/menu-popup.vue";
import SubMenuAddPopup from "@/components/dialogPopup/submenu-popup.vue";
import RawMaterialAddPopup from "@/components/dialogPopup/rawmaterial-popup.vue";
import RecipeAddPopup from "@/components/dialogPopup/recipe-popup.vue";

import SplitButton from "primevue/splitbutton";

const app = createApp(App);

const globalState = reactive({
  loader: false,
});

app.config.globalProperties.$globalState = globalState;

app.use(ToastService);
app.use(PrimeVue);
app.use(ConfirmationService);
app.use(store);

app.use(IdleVue, {
  store,
  idleTime: 1000 * 60 * 10, // 10 min
  startAtIdle: false,
});

app.use(moment);
app.directive("click-outside", clickOutside);
app.directive("prevent-right-click", PreventRightClick);
app.directive("tooltip", Tooltip);
app.component("PrimeCard", Card);

// app.component("LabelTooltip",LabelTooltip);
app.component("AutoComplete", AutoComplete);
app.component("MultiSelect", MultiSelect);
app.component("PrimeToast", Toast);
app.component("ToastService", ToastService);
app.component("DataTable", DataTable);
app.component("Column", Column);
app.component("PrimeButton", Button);
app.component("InputText", InputText);
app.component("InputDate", InputDate);
app.component("Chip", Chip), app.component("Textarea", Textarea);
app.component("InputNumber", InputNumber);
app.component("Dropdown", Dropdown);
app.component("DropDownRequire", DropDownRequire);
app.component("InputTextRequire", InputTextRequire);
app.component("InputPercent", InputGroupPercentage);
app.component("DropdownCustom", DropdownCustom);
app.component("CascadeSelect", CascadeSelect);
app.component("InputGroup", InputGroup);
app.component("InputCustomDate", InputCustomDate);
app.component("NumberCustom", NumberCustom);
app.component("InputNumberRequire", InputNumberRequire);
app.component("Calendar", Calendar);
app.component("Checkbox", Checkbox);
app.component("FileUpload", FileUpload);
app.component("ConfirmPopup", ConfirmPopup);
app.component("InfoPopup", InfoPopup);
app.component("ProgressSpinner", ProgressSpinner);
app.component("RadioButton", RadioButton);
app.component("Editor", Editor);
app.component("EditorRequire", EditorRequire);

app.component("CategoryAddPopup", CategoryAddPopup);
app.component("CategorySelectionPopup", CategorySelectionPopup);
app.component("VendorAddPopup", VendorAddPopup);
app.component("CustomerAddPopup", CustomerAddPopup);
app.component("MenuAddPopup", MenuAddPopup);
app.component("SubMenuAddPopup", SubMenuAddPopup);
app.component("RawMaterialAddPopup", RawMaterialAddPopup);
app.component("RecipeAddPopup", RecipeAddPopup);

app.component("ToggleButton", ToggleButton);
app.component("Paginator", Paginator);

app.component("SplitButton", SplitButton);

app.use(router).mount("#app");
