<template>
  <div class="ml-5 mr-5 set-margin-top mb-5">
    <div class="grid">
      <div class="grid">
        <div class="order-form-title w-full">Event Management</div>
        <div class="order-form-sub-title">{{ subtitle }}</div>
      </div>
      <div class="order-card w-full">
        <div class="grid">
          <div class="md:col-3 sm:col-11 col-9">
            <DropDownRequire
              v-model="vmodel.customerid"
              :validation="v$.vmodel.customerid"
              :options="customerOption"
              id="customer"
              :filter="true"
              class="w-full"
              placeholder="Select Customer"
              label="Customer"
              optionLabel="name"
              optionValue="id"
            />
          </div>
          <div class="sm:col-1 col-3 p-0 mt-5 flex justify-content-center">
            <PrimeButton
              class="download-button"
              icon="pi pi-plus"
              @click="showCustomerPopup = true"
            ></PrimeButton>
          </div>
          <div class="md:col-4 col-12">
            <InputTextRequire
              :validation="v$.vmodel.name"
              v-model="vmodel.name"
              id="name"
              type="text"
              class="w-full"
              label="Event Name"
              placeholder="Enter Event Name"
            />
          </div>
          <div class="md:col-4 col-12">
            <label for="date" class="form-label"> Date and Time </label>
            <Calendar
              v-model="vmodel.date"
              id="date"
              type="text"
              class="w-full mt-2 mb-2"
              label=" Date and Time"
              showIcon
              showTime
              dateFormat="yy-mm-dd"
              placeholder="Select Date and Time"
              :class="{
                'p-invalid': v$.vmodel.date.$error,
              }"
            />
            <span
              v-if="v$.vmodel.date.$error && v$.vmodel.date.required.$invalid"
              id="date-help"
              class="p-error text-left"
            >
              Date and Time is required
            </span>
          </div>
        </div>
        <div class="grid">
          <div class="md:col-8 col-12">
            <InputTextRequire
              v-model="vmodel.venue"
              :validation="v$.vmodel.venue"
              id="venue"
              type="text"
              class="w-full"
              label="Venue"
              placeholder="Enter Venue"
              labelnotshow="false"
            />
          </div>
          <div class="md:col-2 col-12">
            <InputTextRequire
              v-model="vmodel.noofperson"
              :validation="v$.vmodel.noofperson"
              class="w-full"
              type="number"
              id="noofperson"
              label="Number Of Person"
              placeholder="Enter Person"
              :max="999999"
              @keydown="onlyDigit"
              @update:modelValue="setPerson()"
            />
          </div>
          <div class="md:col-2 col-12">
            <DropDownRequire
              v-model="vmodel.status"
              :validation="v$.vmodel.status"
              :options="statusoption"
              id="status"
              :filter="true"
              optionLabel="name"
              placeholder="Select Status"
              optionValue="name"
              class="w-full"
              label="Status"
            />
          </div>
        </div>
        <div class="grid col-12">
          <div
            class="col-4"
            v-for="(eventtype, index) in eventtypeOption"
            :id="index"
            :key="index"
          >
            <RadioButton
              :validation="v$.vmodel.eventtype"
              v-model="vmodel.eventtype"
              :inputId="`${eventtype}-option`"
              name="eventType"
              :value="`${eventtype}`"
            />
            <label class="ml-2 form-label" :for="`${eventtype}-option`">{{
              eventtype
            }}</label>
          </div>
          <span
            v-if="
              v$.vmodel.eventtype.$error &&
              v$.vmodel.eventtype.required.$invalid
            "
            id="eventtype-help"
            class="p-error text-left"
          >
            Event Type is required
          </span>
        </div>

        <div class="grid mt-2">
          <div class="col-12">
            <div class="flex justify-content-between sm:flex-row flex-column">
              <label for="category" class="form-label">Recipe / Submenu</label>
              <div class="sm:mt-0 mt-2">
                <Checkbox
                  :binary="true"
                  inputId="listed"
                  v-model="isSelectedRecipe"
                  @change="emptyItemList"
                /><label for="listed" class="ml-2"> Include Recipe </label>
              </div>
            </div>
            <div id="box" class="box mt-3">
              <div
                class="grid mt-0"
                v-for="(items, index) in vmodel.items"
                :id="index"
                :key="index"
              >
                <div class="md:col-2 col-12 p-0">
                  <div class="grid m-0">
                    <div class="md:col-2 col-1 p-0 flex align-items-center">
                      <i
                        class="draggable-icon cursor-pointer mt-3"
                        :draggable="true"
                        @dragstart="dragStart(index)"
                        @dragover="dragOver(index)"
                        @drop="drop"
                      ></i>
                    </div>
                    <div class="md:col-10 col-10 p-0">
                      <label class="form-label">{{
                        isSelectedRecipe ? "Recipe" : "Submenu"
                      }}</label>
                      <Dropdown
                        v-model="vmodel.items[index].itemid"
                        :options="itemList"
                        :filter="true"
                        optionLabel="name"
                        optionValue="id"
                        id="materials"
                        :maxSelectedLabels="3"
                        placeholder="Select Recipe"
                        class="w-full mt-2 mb-2"
                        v-if="isSelectedRecipe"
                        :class="{
                          'p-invalid':
                            v$.vmodel.items.$each.$response.$errors[index]
                              .itemid[0] && validated,
                        }"
                        @update:modelValue="getItemListDetails(index)"
                      />
                      <CascadeSelect
                        v-model="vmodel.items[index].itemid"
                        :options="
                          itemList.length > 0
                            ? itemList
                            : [
                                {
                                  id: 'No available options',
                                  name: 'No available options',
                                  disabled: true,
                                },
                              ]
                        "
                        optionLabel="name"
                        optionDisabled="disabled"
                        optionGroupLabel="name"
                        optionValue="id"
                        :optionGroupChildren="['submenu']"
                        class="w-full mt-2 mb-2"
                        placeholder="
                      Select Submenu
                    "
                        v-else
                        @update:modelValue="getItemListDetails(index)"
                        :class="{
                          'p-invalid':
                            v$.vmodel.items.$each.$response.$errors[index]
                              .itemid[0] && validated,
                        }"
                      />
                      <span
                        v-if="
                          v$.vmodel.items.$each.$response.$errors[index]
                            .itemid[0] && validated
                        "
                        id="itemid[index]-help"
                        class="p-error text-left"
                      >
                        {{ isSelectedRecipe ? "Recipe" : "Submenu" }} is
                        required
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  class="sm:col-1 col-3 p-1 mt-4 flex justify-content-center"
                >
                  <PrimeButton
                    class="download-button"
                    icon="pi pi-plus"
                    @click="openRecipePopup(index)"
                    v-if="isSelectedRecipe"
                  ></PrimeButton>
                  <PrimeButton
                    class="download-button"
                    icon="pi pi-plus"
                    @click="openSubMenuPopup(index)"
                    v-else
                  ></PrimeButton>
                </div>
                <div
                  class="form-label flex flex-column md:align-items-start align-items-center pl-2"
                >
                  <label class="mb-4">Inbound/Outbound</label>
                  <label class="switch">
                    <Checkbox
                      :binary="true"
                      v-model="vmodel.items[index].checked"
                      class="p-cart"
                      @change="vendorChecked(index)"
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
                <div
                  class="lg:col-2 md:col-5 col-12 p-0 pad-check"
                  v-if="vmodel.items[index].checked"
                >
                  <DropdownCustom
                    v-model="vmodel.items[index].vendorid"
                    :validation="
                      v$.vmodel.items.$each.$response.$errors[index].vendorid[0]
                    "
                    :options="voption"
                    optionLabel="name"
                    optionValue="id"
                    :filter="true"
                    id="vendor"
                    label="Vendor"
                    :showlabel="true"
                    placeholder="Select Vendor"
                    :customfield="true"
                    :validated="validated"
                  />
                </div>
                <div
                  class="lg:col-1 p-0 mt-4 pad-check"
                  v-if="vmodel.items[index].checked"
                >
                  <PrimeButton
                    class="download-button"
                    icon="pi pi-plus"
                    @click="openVendorPopup(index)"
                  ></PrimeButton>
                </div>
                <div class="lg:col-1 md:col-2 col-12 mb-3 p-0 pad-check">
                  <!-- <label class="form-label">Person</label> -->
                  <NumberCustom
                    type="text"
                    v-model="vmodel.items[index].people"
                    :validation="
                      v$.vmodel.items.$each.$response.$errors[index].people[0]
                    "
                    label="Person"
                    id="Person"
                    placeholder="0"
                    :min="0"
                    :max="9999999"
                    maxLangth="6"
                    :customfield="true"
                    :validated="validated"
                  />
                </div>
                <div
                  :class="
                    vmodel.items[index].checked
                      ? 'lg:col-2 md:col-5 sm:col-11 col-12 p-0 pad-check'
                      : 'lg:col-3 md:col-6 col-12 p-0 pad-check'
                  "
                >
                  <InputGroup
                    type="text"
                    v-model="vmodel.items[index].remarks"
                    :validation="
                      v$.vmodel.items.$each.$response.$errors[index].remarks[0]
                    "
                    class="w-full remarksnote"
                    id="remarks"
                    label="Remark"
                    placeholder="Remark"
                    :customfield="true"
                    :validated="validated"
                  />
                </div>
                <div
                  class="md:col-2 col-12 flex bin-btn justify-content-end relative p-0"
                  :class="
                    vmodel.items[index].checked
                      ? 'lg:col-1 md:col-2'
                      : 'lg:col-3 md:col-2'
                  "
                >
                  <PrimeButton
                    icon="bin-icon"
                    class="bin-button"
                    @click="removeItem(index)"
                  />
                </div>
              </div>
              <div class="grid">
                <div class="col-4 field">
                  <PrimeButton
                    icon="pi pi-plus"
                    class="add-item-button"
                    @click="repeateAgain()"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="grid mx-0 mt-4">
          <div class="col-12 flex set-button">
            <PrimeButton
              label="Add"
              class="order-form-button"
              v-if="!isEditMode"
              @click="createEvent"
            >
            </PrimeButton>
            <PrimeButton
              label="Edit"
              class="order-form-button"
              v-else
              @click="createEvent"
            >
            </PrimeButton>
            <PrimeButton
              label="Cancel"
              class="form-cancel-button sm:ml-2"
              @click="onCancel"
            ></PrimeButton>
          </div>
        </div>
      </div>
    </div>
  </div>
  <CustomerAddPopup
    :show-popup="showCustomerPopup"
    @displayNo="closeCustomerPopup()"
    @displayYes="checkCustomer"
  ></CustomerAddPopup>
  <SubMenuAddPopup
    :show-popup="showSubMenuPopup"
    @displayNo="closeSubMenuPopup()"
    @displayYes="checkSubMenu"
  ></SubMenuAddPopup>
  <RecipeAddPopup
    :show-popup="showRecipePopup"
    @displayNo="closeRecipePopup()"
    @displayYes="checkRecipe"
  ></RecipeAddPopup>
  <VendorAddPopup
    :show-popup="showVendorPopup"
    @displayNo="closeVendorPopup()"
    @displayYes="checkVendor"
  ></VendorAddPopup>
</template>

<script>
import APIService from "@/services/api-service.js";
import DateFormat from "@/libs/DateFormat";
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  maxLength,
  numeric,
  maxValue,
  minValue,
  helpers,
  requiredIf,
} from "@vuelidate/validators";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      subtitle: "Add Event",
      datetime12h: null,
      dragIndex: null,
      isEditMode: false,
      customerOption: [],
      eventtypeOption: [],
      item: [],
      addedItemList: [],
      itemList: [],
      items: [],
      voption: [],
      showCustomerPopup: false,
      showSubMenuPopup: false,
      showRecipePopup: false,
      showVendorPopup: false,
      isSelectedRecipe: false,
      validated: false,
      openIndex: "",
      statusoption: [
        { name: "Inquiry" },
        { name: "Confirm" },
        { name: "Cancel" },
      ],
      title: "Add",
      vmodel: {
        customerid: "",
        name: "",
        noofperson: 1,
        status: "",
        venue: "",
        date: "",
        eventtype: "",
        items: [
          {
            name: "",
            itemid: "",
            menuid: "",
            menuname: "",
            checked: false,
            vendorid: "",
            people: 1,
            remarks: "",
          },
        ],
      },
    };
  },
  validations() {
    return {
      vmodel: {
        customerid: { required },
        name: { required, max: maxLength(40) },
        noofperson: {
          required,
          numeric,
          min: minValue(1),
          max: maxValue(999999),
        },
        eventtype: { required },
        status: { required },
        venue: { required, max: maxLength(200) },
        date: { required },
        items: {
          $each: helpers.forEach({
            itemid: { required },
            people: {
              required,
              numeric,
              min: minValue(1),
              max: maxValue(99999),
            },
            vendorid: {
              requiredIf: requiredIf((items, index) => {
                return index.checked;
              }),
            },
            remarks: { max: maxLength(50) },
          }),
        },
      },
    };
  },
  async created() {
    await this.getEventType();
  },
  async mounted() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if (this.$route.params.id) {
      this.subtitle = "Update Event";
      this.isEditMode = true;
      await this.getEventById();
    }
    if (this.isSelectedRecipe) {
      await this.getRecipe();
    } else {
      await this.getsubmenu();
    }
    this.$root.$globalState.loader = false;
    await this.getCustomerDrop();
    await this.getVendor();
  },
  methods: {
    async getEventType() {
      this.$root.$globalState.loader = true;
      await APIService.get("/company/eventtype")
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.eventtypeOption = response.data.data;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
          this.submitted = false;
        });
    },
    setPerson() {
      if (this.vmodel.items) {
        for (let item of this.vmodel.items) {
          item.people = this.vmodel.noofperson;
        }
      }
    },
    dragStart(index) {
      this.dragIndex = index;
    },
    dragOver(index) {
      if (this.dragIndex !== null) {
        const draggedItem = this.vmodel.items.splice(this.dragIndex, 1)[0];
        this.vmodel.items.splice(index, 0, draggedItem);
        this.dragIndex = index;
      }
    },
    drop() {
      this.dragIndex = null;
    },
    closeCustomerPopup() {
      this.showCustomerPopup = false;
    },
    async checkCustomer(data) {
      this.showCustomerPopup = false;
      this.vmodel.customerid = data;
      await this.getCustomerDrop();
    },
    openRecipePopup(index) {
      this.showRecipePopup = true;
      this.openIndex = index;
    },
    closeRecipePopup() {
      this.showRecipePopup = false;
    },
    async checkRecipe(data) {
      this.showRecipePopup = false;
      await this.getRecipe();
      await this.getVendor();
      await this.setRecipeDetails(data);
      // if (this.isSelectedRecipe) {
      //   this.items
      //     .filter((x) => x.id === this.vmodel.items[index].itemid)
      //     .map((data) => {
      //       this.vmodel.items[index].recipeid = this.vmodel.items[index].itemid;
      //       this.vmodel.items[index].recipename = data.name;
      //       this.vmodel.items[index].people = this.vmodel.noofperson;
      //       this.vmodel.items[index].remarks = "";
      //     });
      // } else {
      //   this.items
      //     .filter((x) =>
      //       x.submenu.some(
      //         (subElement) => subElement.id === this.vmodel.items[index].itemid
      //       )
      //     )
      //     .map((element) => {
      //       element.sub = element.submenu
      //         .filter(
      //           (subElement) =>
      //             subElement.id === this.vmodel.items[index].itemid
      //         )
      //         .map((x) => {
      //           let newElt = Object.assign({}, x);
      //           return newElt;
      //         });
      //       this.vmodel.items[index].submenuid = element.sub[0].id;
      //       this.vmodel.items[index].name = element.sub[0].name;
      //       this.vmodel.items[index].menuid = element.sub[0].menuid;
      //       this.vmodel.items[index].menuname = element.name;
      //       this.vmodel.items[index].people = this.vmodel.noofperson;
      //       this.vmodel.items[index].remarks = "";
      //     });
      // }
      // if (this.vmodel.items) {
      //   let index = this.vmodel.items.length - 1;
      //   this.vmodel.items[index].itemid = data;
      //   this.getItemListDetails(index);
      // }
    },
    async setRecipeDetails(data) {
      if (this.vmodel.items) {
        let index = this.openIndex;
        if (this.vmodel.items[index].itemid == "") {
          this.vmodel.items[index].itemid = data;
          this.vmodel.items[index].recipeid = data;
          await this.itemList
            .filter((x) => x.id == data)
            .map((data) => {
              this.vmodel.items[index].recipename = data.name;
              this.vmodel.items[index].people = this.vmodel.noofperson;
              this.vmodel.items[index].vendorid = "";
              this.vmodel.items[index].remarks = "";
              this.vmodel.items[index].checked = false;
            });
        }
        // } else {
        //   const newItem = {
        //     recipename: "",
        //     itemid: "",
        //     recipeid: "",
        //     people: 1,
        //     vendorid: "",
        //     remarks: "",
        //     checked: false,
        //   };
        //   this.vmodel.items = [...this.vmodel.items, newItem];
        //   index = this.vmodel.items.length - 1;
        //   this.vmodel.items[index].itemid = data;
        //   this.vmodel.items[index].recipeid = data;
        //   await this.itemList
        //     .filter((x) => x.id == data)
        //     .map((data) => {
        //       this.vmodel.items[index].recipename = data.name;
        //       this.vmodel.items[index].people = 1;
        //       this.vmodel.items[index].vendorid = "";
        //       this.vmodel.items[index].remarks = "";
        //       this.vmodel.items[index].checked = false;
        //     });
        // }
        await this.getItemListDetails(index);
      }
      this.openIndex = "";
    },
    openSubMenuPopup(index) {
      this.openIndex = index;
      this.showSubMenuPopup = true;
    },
    closeSubMenuPopup() {
      this.showSubMenuPopup = false;
    },
    async checkSubMenu(data) {
      this.showSubMenuPopup = false;
      await this.getsubmenu();
      await this.getVendor();
      await this.setSubmenu(data);
      // if (this.vmodel.items) {
      //   let index = this.vmodel.items.length - 1;
      //   this.vmodel.items[index].itemid = data;
      //   this.getItemListDetails(index);
      // }
    },
    async setSubmenu(data) {
      if (this.vmodel.items) {
        if (this.vmodel.items[this.openIndex].itemid == "") {
          this.vmodel.items[this.openIndex].itemid = data;
          await this.items
            .filter((x) =>
              x.submenu.some((subElement) => subElement.id == data)
            )
            .map((element) => {
              element.sub = element.submenu
                .filter((subElement) => subElement.id == data)
                .map((x) => {
                  let newElt = Object.assign({}, x);
                  return newElt;
                });
              this.vmodel.items[this.openIndex].submenuid = element.sub[0].id;
              this.vmodel.items[this.openIndex].name = element.sub[0].name;
              this.vmodel.items[this.openIndex].menuid = element.sub[0].menuid;
              this.vmodel.items[this.openIndex].menuname = element.name;
              this.vmodel.items[this.openIndex].people = this.vmodel.noofperson;
              this.vmodel.items[this.openIndex].remarks = "";
              this.vmodel.items[this.openIndex].checked = false;
            });
        }
        // else {
        //   const newItem = {
        //     name: "",
        //     itemid: "",
        //     submenuid: "",
        //     menuid: "",
        //     menuname: "",
        //     people: 1,
        //     vendorid: "",
        //     remarks: "",
        //     checked: false,
        //   };
        //   this.vmodel.items = [...this.vmodel.items, newItem];
        //   index = this.vmodel.items.length - 1;
        //   this.vmodel.items[index].itemid = data;
        //   await this.items
        //     .filter((x) =>
        //       x.submenu.some(
        //         (subElement) =>
        //           subElement.id === this.vmodel.items[index].itemid
        //       )
        //     )
        //     .map((element) => {
        //       element.sub = element.submenu
        //         .filter((subElement) => subElement.id == data)
        //         .map((x) => {
        //           let newElt = Object.assign({}, x);
        //           return newElt;
        //         });
        //       this.vmodel.items[index].submenuid = element.sub[0].id;
        //       this.vmodel.items[index].name = element.sub[0].name;
        //       this.vmodel.items[index].menuid = element.sub[0].menuid;
        //       this.vmodel.items[index].menuname = element.name;
        //       this.vmodel.items[index].people = this.vmodel.noofperson;
        //       this.vmodel.items[index].remarks = "";
        //       this.vmodel.items[index].checked = false;
        //     });
        // }
        await this.getItemListDetails(this.openIndex);
      }
      this.openIndex = "";
    },
    openVendorPopup(index) {
      this.showVendorPopup = true;
      this.openIndex = index;
    },
    closeVendorPopup() {
      this.showVendorPopup = false;
    },
    async checkVendor(data) {
      this.showVendorPopup = false;
      await this.getVendor();
      if (this.vmodel.items) {
        this.vmodel.items[this.openIndex].vendorid = data;
        await this.getItemListDetails(this.openIndex);
      }
      this.openIndex = "";
    },
    async vendorChecked(index) {
      if (!this.vmodel.items[index].checked) {
        this.vmodel.items[index].vendorid = "";
      }
    },
    async getVendor() {
      this.$root.$globalState.loader = true;
      await APIService.get("/vendor/vender")
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.voption = response.data.data;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
          this.submitted = false;
        });
    },
    onlyDigit($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (
        (this.vmodel.noofperson >= 999999 &&
          keyCode > 47 &&
          keyCode < 58 &&
          keyCode !== 8 &&
          keyCode !== 32) ||
        (this.vmodel.noofperson >= 999999 &&
          keyCode !== 229 &&
          keyCode !== 8 &&
          keyCode !== 32)
      )
        $event.preventDefault();
    },
    onCancel() {
      this.$router.push({ name: "Event" });
    },
    async emptyItemList() {
      this.vmodel.items = [];
      this.addedItemList = [];
      if (this.isSelectedRecipe) {
        await this.getRecipe();
        this.vmodel.items = [
          {
            recipename: "",
            itemid: "",
            recipeid: "",
            people: 1,
            vendorid: "",
            remarks: "",
            checked: false,
          },
        ];
      } else {
        await this.getsubmenu();
        this.vmodel.items = [
          {
            name: "",
            itemid: "",
            submenuid: "",
            menuid: "",
            menuname: "",
            people: 1,
            vendorid: "",
            remarks: "",
            checked: false,
          },
        ];
      }
    },
    async getItemListDetails(index) {
      let check1 = this.addedItemList.indexOf(this.vmodel.items[index].itemid);
      if (check1 !== -1 && index !== check1) {
        this.vmodel.items[index].itemid = "";
        if (this.isSelectedRecipe) {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Recipe already added.",
            life: 3000,
          });
          return;
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Sub Menu already added.",
            life: 3000,
          });
          return;
        }
      }

      if (this.addedItemList.length > 0) {
        if (this.addedItemList[index]) {
          this.addedItemList[index] = this.vmodel.items[index].itemid;
        } else {
          this.addedItemList.push(this.vmodel.items[index].itemid);
        }
      } else {
        this.addedItemList.push(this.vmodel.items[index].itemid);
      }

      if (this.isSelectedRecipe) {
        await this.items
          .filter((x) => x.id === this.vmodel.items[index].itemid)
          .map((data) => {
            this.vmodel.items[index].recipeid = this.vmodel.items[index].itemid;
            this.vmodel.items[index].recipename = data.name;
            this.vmodel.items[index].people = this.vmodel.noofperson;
            this.vmodel.items[index].remarks = "";
          });
      } else {
        await this.items
          .filter((x) =>
            x.submenu.some(
              (subElement) => subElement.id === this.vmodel.items[index].itemid
            )
          )
          .map((element) => {
            element.sub = element.submenu
              .filter(
                (subElement) =>
                  subElement.id === this.vmodel.items[index].itemid
              )
              .map((x) => {
                let newElt = Object.assign({}, x);
                return newElt;
              });
            this.vmodel.items[index].submenuid = element.sub[0].id;
            this.vmodel.items[index].name = element.sub[0].name;
            this.vmodel.items[index].menuid = element.sub[0].menuid;
            this.vmodel.items[index].menuname = element.name;
            this.vmodel.items[index].people = this.vmodel.noofperson;
            this.vmodel.items[index].remarks = "";
          });
      }
    },
    async getsubmenu() {
      this.itemList = [];
      this.items = [];
      this.$root.$globalState.loader = true;
      await APIService.get("/menu/dropdown/onlysubmenu")
        .then((response) => {
          if (response && response.data && response.data.success) {
            this.itemList = response.data.data;
            this.items = response.data.data;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
          this.submitted = false;
        });
    },
    async getRecipe() {
      this.itemList = [];
      this.items = [];
      this.$root.$globalState.loader = true;
      await APIService.get("/recipe/recipe")
        .then((response) => {
          if (response && response.data && response.data.success) {
            this.itemList = response.data.data;
            this.items = response.data.data;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
          this.submitted = false;
        });
    },
    repeateAgain() {
      let newItem;
      if (this.isSelectedRecipe) {
        newItem = {
          recipename: "",
          itemid: "",
          recipeid: "",
          vendorid: "",
          people: this.vmodel.noofperson,
          remarks: "",
          checked: false,
        };
      } else {
        newItem = {
          name: "",
          submenuid: "",
          itemid: "",
          menuid: "",
          menuname: "",
          vendorid: "",
          people: this.vmodel.noofperson,
          remarks: "",
          checked: false,
        };
      }
      this.vmodel.items = [...this.vmodel.items, newItem];
    },
    removeItem(index) {
      this.addedItemList.splice(index, 1);
      this.vmodel.items.splice(index, 1);
    },
    async getCustomerDrop() {
      this.$root.$globalState.loader = true;
      await APIService.get("/customer/customer")
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.customerOption = response.data.data;
          }
        })
        .finally(() => {
          this.$root.$globalState.loader = false;
          this.submitted = false;
        });
    },
    async createEvent() {
      this.validated = true;
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      else {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        this.$root.$globalState.loader = true;
        let payload = {
          customerid: this.vmodel.customerid,
          eventname: this.vmodel.name,
          people: this.vmodel.noofperson,
          status: this.vmodel.status,
          venue: this.vmodel.venue,
          date: this.vmodel.date,
          time: DateFormat.setTime(this.vmodel.date),
          items: this.vmodel.items,
          eventtype: this.vmodel.eventtype,
        };

        this.submitted = true;
        if (this.$route.params.id) {
          await APIService.put("/event", payload, this.$route.params.id)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "Event" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
              this.$root.$globalState.loader = false;
            });
        } else {
          await APIService.post("/customerevent", payload)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });

                setTimeout(() => {
                  this.$router.push({ name: "Event" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
              this.$root.$globalState.loader = false;
            });
        }
      }
    },
    async getEventById() {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      this.$root.$globalState.loader = true;
      await APIService.getById("/event", this.$route.params.id)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.vmodel.customerid = response.data.data.customerid;
            this.vmodel.name = response.data.data.eventname;
            this.vmodel.noofperson = response.data.data.people;
            this.vmodel.status = response.data.data.status;
            this.vmodel.venue = response.data.data.venue;
            this.vmodel.eventtype = response.data.data.eventtype;
            this.vmodel.date = new Date(
              DateFormat.formatDateTime(response.data.data.eventdate)
            );
            this.item = response.data.data.items;
            this.vmodel.items = response.data.data.items;
            this.isSelectedRecipe = response.data.data.hasRecipe;
            if (response.data.data.items) {
              for (let item of response.data.data.items) {
                if (response.data.data.hasRecipe) {
                  item.itemid = item.recipeid;
                } else {
                  item.itemid = item.submenuid;
                }
                if (item.vendorid) {
                  item.checked = true;
                } else {
                  item.checked = false;
                }
              }
            }
            this.addedItemList = this.vmodel.items.map((x) => x.itemid);
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
            setTimeout(() => {
              this.$router.push({ name: "Event" });
            });
          }
        })
        .finally(() => {
          this.submitted = false;
          this.$root.$globalState.loader = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.add-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 10px;
  gap: 8px;
  height: 36px;
  background: #ff9f43 !important;
  border-radius: 8px;
  border: none;
}
.add-button :hover {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 10px;
  gap: 8px;
  height: 36px;
  background: #ff9f43 !important;
  border-radius: 8px;
  border: none;
}

.box {
  border-radius: 8px;
  border: 1px solid #ccc9d6;
  padding: 20px;
  height: auto;
}

.qty-style {
  width: 36px;
  margin-top: -21px;
  border-radius: unset;
  border-left: none;
  border-right: none;
  padding-left: 11px;
}

.bin-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px !important;
  gap: 8px;
  height: 36px;
  width: 36px !important;
  margin-top: 38px;
  margin-left: 61px;
  background: #feeded;
  opacity: 0.4;
  border: 1px solid rgba(241, 71, 71, 0.4);
  border-radius: 4px;
  margin-left: 10px;
  margin-top: 28px;
}

.bin-button:enabled:hover {
  background: #feeded;
  border: 1px solid rgba(241, 71, 71, 0.4);
}
.add-item-button:enabled:hover {
  background: #06253c;
  color: #fff;
  border-color: #06253c;
}
.add-item-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  height: 37px !important;
  width: 37px !important;
  background: #06253c;
  border-radius: 4px;
  border: #06253c;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  color: #fff;
}
.bin-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px !important;
  gap: 8px;
  height: 36px;
  width: 36px !important;

  background: #feeded;
  opacity: 0.4;
  border: 1px solid rgba(241, 71, 71, 0.4);
  border-radius: 4px;
}

.bin-button:enabled:hover {
  background: #feeded;
  border: 1px solid rgba(241, 71, 71, 0.4);
}

:deep(.p-multiselect-label) {
  height: 48px !important;
}
:deep(.p-inputnumber .p-inputtext) {
  width: 50px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-left: 31px;
  margin-top: 10px;
  top: -18px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

::v-deep(.p-checkbox.p-cart) {
  display: none;
}

.p-checkbox-checked + .slider {
  background-color: #ff9f43;
}

.p-checkbox:focus + .slider {
  box-shadow: 0 0 1px #ff9f43;
}

.p-checkbox-checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.pad-check {
  padding: 0px 0px 0px 10px !important;
}
</style>
